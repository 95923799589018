import { useState, useEffect } from 'react';
import { requestData } from '../../../../api';

export const useGetAppUrls = () => {
  const [isLoading, updateLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState({ type: '' });

  const getUrlsApi = (formData) => {
    const options = {
      method: 'POST',
      body: formData
    };
    return requestData({
      url: `/api/v3/enquiry_requests`,
      options
    });
  };

  const fetchUrls = async (formData, clearFields, updateModalStatus) => {
    try {
      updateLoading(true);
      await getUrlsApi(formData);
      setAlertMessage({
        type: 'success'
      });
      clearFields();
      updateModalStatus(true);
    } catch (err) {
      console.error(err);
      setAlertMessage({
        type: 'error'
      });
      updateModalStatus(true);
    } finally {
      updateLoading(false);
    }
  };

  return {
    isLoading,
    alertMessage,
    fetchUrls
  };
};

import React, { useMemo, useState, useEffect, useCallback } from 'react';

import firebase from 'firebase/app';
import '../../../firebaseConfig';

import {
  validateName,
  validateEmail,
  validatePhone,
  validateSubject,
  validateMessage
} from '../../../Helpers/helpers';

import Input from '../../../Components/InputBox/Input';
import Button from '../../../Components/Button/Button';

import {
  SUPPORT_TEXT_1,
  SUPPORT_TEXT_2,
  SUPPORT_TEXT_3,
  SUPPORT_TEXT_4,
  SUPPORT_TEXT_5,
  SUPPORT_TEXT_6,
  SUPPORT_TEXT_7,
  SUPPORT_TEXT_8
} from '../../../Constants';

import styles from './postmessage.module.css';
import { useGetAppUrls } from './hooks/useContactCreate';
import Modal from '../../../Components/Modal/Modal';
import Icon from '../../../Components/Icon/Icon';

export const validate = (values) => {
  const errors = {};
  if (!values?.name) errors.name = true;
  if (!validateEmail(values?.email) && !validatePhone(values?.phone))
    errors.email = true;
  if (!values?.message) errors.message = true;
  return errors;
};

function PostMessage() {
  const { isLoading, alertMessage, fetchUrls } = useGetAppUrls();

  const [field, setField] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const [isModalActive, updateModalStatus] = useState(false);

  const handleChange = (e) => {
    e.persist();
    if (Object.keys(errorMessages).length > 0) {
      setErrorMessages({});
    }
    setField({
      ...field,
      [e.target.name]: e.target.value
    });
  };

  const clearFields = () => {
    setField({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      const validateObj = validate(field);
      if (Object.keys(validateObj).length > 0) {
        setErrorMessages(validateObj);
      } else {
        setErrorMessages({});
        const formData = new FormData();
        formData.append('enquiry_request[name]', field.name);
        formData.append('enquiry_request[email]', field?.email || '');
        formData.append('enquiry_request[phone]', field?.phone || '');
        formData.append('enquiry_request[message]', field.message);

        fetchUrls(formData, clearFields, updateModalStatus);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleModal = useCallback(() => {
    updateModalStatus((prevValue) => !prevValue);
  }, []);

  return (
    <div className={styles.container}>
      <div className={`${styles.content} center`}>
        <div className={styles.header}>
          <p className="heading_text white_heading">{SUPPORT_TEXT_1}</p>
          <p className="body_text">{SUPPORT_TEXT_2}</p>
        </div>

        <form className={`${styles.input_form} center`} onSubmit={handleSubmit}>
          <div className={styles.input_field1}>
            <Input
              type="text"
              name="name"
              placeholder={SUPPORT_TEXT_3}
              value={field?.name || ''}
              onChange={handleChange}
              error={errorMessages?.name}
              errMsg="Name is invalid."
            />
            <Input
              type="text"
              name="email"
              placeholder={SUPPORT_TEXT_4}
              value={field?.email || ''}
              onChange={handleChange}
              error={errorMessages?.email}
              errMsg="Email is invalid."
            />
            <Input
              type="text"
              name="phone"
              placeholder={SUPPORT_TEXT_5}
              value={field?.phone || ''}
              onChange={handleChange}
              error={errorMessages?.email}
              errMsg="Phone No. is invalid."
            />
          </div>
          <div className={styles.input_field2}>
            <Input
              type="textarea"
              name="message"
              className={styles.text_area}
              placeholder={SUPPORT_TEXT_7}
              value={field?.message || ''}
              onChange={handleChange}
              error={errorMessages?.message}
              errMsg="Message cannot be blank."
            />
          </div>
          <Button
            text={SUPPORT_TEXT_8}
            isLoading={isLoading}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          />
        </form>
        <Modal show={isModalActive}>
          <div className={styles.modal_container}>
            <button className={styles.modal_cross_btn} onClick={toggleModal}>
              X
            </button>
            <div
              className={styles.modal_icon_box}
              style={{
                backgroundColor:
                  alertMessage.type === 'success' ? 'green' : 'red'
              }}>
              <Icon
                icon={alertMessage.type === 'success' ? 'check' : 'times'}
                size="lg"
                style={{ color: 'white' }}
              />
            </div>
            <h1 className={styles.modal_header}>
              {alertMessage.type === 'success' ? 'Success!' : 'Oops!'}
            </h1>
            <p className={styles.modal_text}>
              {alertMessage.type === 'success'
                ? 'Your enquiry has been submitted. Check your email for details. We will get back to you soon!'
                : 'Something went wrong.'}
            </p>
            <button
              className={styles.modal_close_btn}
              style={{
                backgroundColor:
                  alertMessage.type === 'success' ? 'green' : 'red'
              }}
              onClick={toggleModal}>
              {alertMessage.type === 'success' ? 'Ok' : 'Try again'}
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
}
export default PostMessage;

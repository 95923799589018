import React from 'react';
import styles from './input.module.css';

const Input = (props) => {
  return props.type === 'text' ? (
    <div className={styles.container}>
      <input
        className={`${styles.text_field} ${
          props.error === true ? styles.error_text_field : ''
        }`}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.onChange(e)}
      />
      <label className={`${styles.errMsg} errMsg`}>
        {props.error === true ? props.errMsg : null}
      </label>
    </div>
  ) : (
    <div className={`${styles.container}  ${styles.text_area_container}`}>
      <textarea
        className={`${styles.text_field} ${
          props.error === true ? styles.error_text_field : ''
        } ${styles.text_area}`}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        value={props.value}
        onChange={(e) => props.onChange(e)}
      />
      <label className={`${styles.errMsg} errMsg`}>
        {props.error === true ? props.errMsg : null}
      </label>
    </div>
  );
};

export default Input;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './ActivityIndicator.module.css';

const getSizeClass = (size) => {
  switch (size) {
    case 'medium':
      return styles.activity_indicator_m;
    case 'large':
      return styles.activity_indicator_l;
    case 'small':
    default:
      return styles.activity_indicator_s;
  }
};

const getBorderWidth = (size) => {
  switch (size) {
    case 'medium':
      return '6px';
    case 'large':
      return '8px';
    case 'small':
    default:
      return '3px';
  }
};

const ActivityIndicator = ({ color, size }) => {
  const sizeClass = getSizeClass(size);
  const borderWidth = getBorderWidth(size);

  return (
    <div className={`${styles.activity_indicator} ${sizeClass}`}>
      <div
        style={{
          borderWidth
        }}
        className={styles.activity_indicator_bar_1}
      />
      <div
        style={{
          borderColor: color,
          borderWidth
        }}
        className={styles.activity_indicator_bar_2}
      />
    </div>
  );
};

ActivityIndicator.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large'])
};

ActivityIndicator.defaultProps = {
  color: 'black',
  size: 'small'
};

export default ActivityIndicator;

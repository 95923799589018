import React from 'react';

import { Link } from 'react-router-dom';

import Playstore from '../../../Components/PlayStore/PlayStore';
import Image from '../../../Components/Image/Image';

import sociana_icon_png from '../../../Assets/Contact/sociana_logo.png';
import sociana_icon_webp from '../../../Assets/Contact/sociana_logo.webp';

import {
  SUPPORT_TEXT_9,
  SUPPORT_TEXT_10,
  SUPPORT_TEXT_11,
  SUPPORT_TEXT_12,
  SUPPORT_TEXT_13,
  SUPPORT_TEXT_14,
  SUPPORT_TEXT_15
} from '../../../Constants/index';

import styles from './getintouch.module.css';

const GetInTouch = () => (
  <div className={`${styles.container} center`}>
    <div className={`${styles.content} center`}>
      {/* <img className={styles.logo} src={sociana_icon} alt="logo" /> */}
      <Image
        className={styles.logo}
        src={sociana_icon_png}
        webpImage={sociana_icon_webp}
        alt="logo"
      />
      <div className={styles.links_box}>
        <Playstore />
        <div className={`${styles.links} center`}>
          <Link
            to="/termsandconditions"
            className={`secondary_body_text`}
            target="_blank">
            {SUPPORT_TEXT_9}
          </Link>
          <Link
            to="/privacypolicy"
            className={`secondary_body_text`}
            target="_blank">
            {SUPPORT_TEXT_10}
          </Link>
          <Link
            to="/frequentlyaskedquestions"
            className={`secondary_body_text`}
            target="_blank">
            {SUPPORT_TEXT_11}
          </Link>
        </div>
      </div>
      <p className={`${styles.contact_details} body_text`}>
        <span className={`secondary_body_text`}>{SUPPORT_TEXT_12}</span>
        <span>{SUPPORT_TEXT_13}</span>
        <br />
        &nbsp;&nbsp;&nbsp;
        <span className={`secondary_body_text`}>{SUPPORT_TEXT_14}</span>
        <span>{SUPPORT_TEXT_15}</span>
      </p>
    </div>
  </div>
);

export default GetInTouch;

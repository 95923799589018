import React from 'react';

import Image from '../Image/Image';

import googleplay_png from '../../Assets/Playstore/playstore.png';
import googleplay_webp from '../../Assets/Playstore/playstore.webp';
import appstore_png from '../../Assets/Playstore/appstore.png';
import appstore_webp from '../../Assets/Playstore/appstore.webp';

import styles from './playstore.module.css';

const PlayStore = () => (
  <div className={styles.container}>
    <a
      href="https://play.google.com/store/apps/details?id=com.alienbrains.sociana&hl=en"
      target="_blank"
      rel="noopener noreferrer">
      {/* <img className={styles.android} src={googleplay} alt="googleplay" /> */}
      <Image
        className={`${styles.container_img} ${styles.android}`}
        src={googleplay_png}
        webpImage={googleplay_webp}
        alt="GooglePlay"
      />
    </a>
    <a
      href="https://apps.apple.com/us/app/sociana/id1477953677"
      target="_blank"
      rel="noopener noreferrer">
      {/* <img className={styles.ios} src={appstore} alt="appstore" /> */}
      <Image
        className={styles.container_img}
        src={appstore_png}
        webpImage={appstore_webp}
        alt="AppStore"
      />
    </a>
  </div>
);

export default PlayStore;

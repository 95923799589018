import { SERVER_URL } from '../client_config';

export const requestData = ({ url, options }) => {
  const apiUrl = `${SERVER_URL}${url}`;
  return fetch(apiUrl, options)
    .then((res) => {
      if (res.ok && res.status === 200) {
        return res.json();
      }
      const error = new Error('Failed to fetch data');
      throw error;
    })
    .catch((err) => {
      throw err;
    });
};

import React from 'react';
import ActivityIndicator from '../ActivityIndicator';

const Button = (props) => {
  const { isLoading, text, ...restProps } = props;
  return (
    <button className={`button_pm`} type="submit" {...restProps}>
      {isLoading ? <ActivityIndicator /> : text}
    </button>
  );
};

export default Button;

import React from 'react';
import PostMessage from './PostMessage';
import GetInTouch from './GetInTouch';

const Support = () => (
  <div data-testid="contact" id="contact">
    <PostMessage />
    <GetInTouch />
  </div>
);

export default Support;
